import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Tips To Overcome Culture Shock After the Army",
  "author": "Anthony",
  "date": "2018-03-28T05:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "tips-to-overcome-culture-shock-after-the-army",
  "draft": false,
  "meta_title": "Tips To Overcome Culture Shock After the Army"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`cul·ture shock (`}<em parentName="strong">{`noun`}</em>{`):`}</strong>{` the feeling of disorientation experienced by someone who is suddenly subjected to an unfamiliar culture, way of life, or set of attitudes.`}</p>
    <p>{`The most common hurdles in military transitions are frequently discussed: employment, VA benefits, paperwork, medical out processing, etc. But what other elements of this transition experience exist that aren’t being as openly discussed?`}</p>
    <p>{`We often tend to keep “culture shock” in strict reference to locational or geographical changes. A deployment to the Middle East warrants culture shock. A duty assignment in Korea warrants culture shock. A redeployment from Iraq back to Fort Bragg after 15 months is an acceptable reason for culture shock. (Even a PCS from Fort Drum to Fort Polk can be deemed worthy of culture shock. If you’ve been to Fort Polk, you’ll get it.)`}</p>
    <p>{`But what happens when the “shock” isn’t so widely accepted or understood? It quickly changes from a cliché term thrown around in vague description to something deeply personal and conflicting.`}</p>
    <p>{`If you ask a transitioned soldier what the hardest part of assimilating into a civilian job was, it's going to be navigating through the unfamiliar culture, daily routines, interactions, attitudes, language, jargon, terminologies, and values of a civilian company. It’s not a simple job transfer. It’s not as easy as “took a new job today.” It’s a deep mental shift from one way of life to another and the more we continue to acknowledge, accept, and discuss this phase of transition, the better off our soldiers, families, and services will be.`}</p>
    <p>{`A recent independent `}<a parentName="p" {...{
        "href": "http://www.va.gov/VETSINWORKPLACE/docs/Veterans_in_Workplace_Final_Report.pdf"
      }}>{`study`}</a>{` on veterans in the workplace from the Burton Blatt Institute and Competitive Edge Services reports that “transition experiences can be complicated by a number of factors: physical and psychological service-related injuries (including PTSD), the lack of an easy way to communicate one’s experience and skills, and the lack of a written rule book on the prevailing unspoken corporate rules. Some reported being misunderstood by co-workers due to differences in one’s manner, expectations, and speech. Others were frustrated by the lack of a clear chain of command and a clear path for advancement. Others described missing the sense of mission and urgency within the military that resulted from knowing that the lives of others might depend on the speed and quality of one’s own work. Some also spoke of missing the camaraderie and bonds fostered by working and living in close quarters and depending on each other in critical, life and death situations. And others described how losing such bonds can create feelings of painful loneliness.”`}</p>
    <p>{`In short, culture shock.`}</p>
    <p>{`So how do you assimilate? How do you ease the stress of the cultural transition? In an ideal world, observation, research, and time to adapt would be key. But in a fast-moving market, on the job training and job performance are expected immediately leaving little time to ease into the culture of your new career. So as you prepare, here are a few tips to keep in mind:`}</p>
    <p><strong parentName="p">{`Knowledge is power`}</strong>{`. Ask questions. Be bold and clear in your intentions. At any point in the employment seeking process the following set of questions are appropriate to ask:`}</p>
    <p>{`“Could you explain the chain of command within your company’s structure?”`}</p>
    <p>{`“How would you describe the daily pace of work here?”`}</p>
    <p>{`“What are the defining elements of the team culture in this company?”`}</p>
    <p>{`This short clip will provide you great additional material for conversations with potential employers: `}<a parentName="p" {...{
        "href": "http://www.forbes.com/sites/nextavenue/2014/06/18/10-job-interview-questions-you-should-ask/#a4c4047191e1"
      }}>{`Top Ten Interview Questions to Ask an Employer`}</a></p>
    <p><strong parentName="p">{`Stay involved`}</strong>{`. Staying connected to a community the understands where you’ve been, what you’ve done, where you struggle, where you thrive, and where you are going is life-preserving. Even if you relocate and are far removed from a military installation or active military community, still seek out ways to remain connected. Veteran organizations that offer local chapters like `}<a parentName="p" {...{
        "href": "https://www.teamrwb.org/"
      }}>{`Team RWB`}</a>{` or `}<a parentName="p" {...{
        "href": "http://www.teamrubiconusa.org/"
      }}>{`Team Rubicon`}</a>{` that offer events, get-togethers, and physical training outlets are incredible morale boosters, networking, and de-stressor opportunities. Engaging in LinkedIn or Facebook communities geared towards veteran networking and support also provides real-time, interactive platforms to stay engaged. `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/ted-talks-for-veterans-in-transition-how-to-talk-so-people-will-listen/"
      }}>{`Don’t shut yourself out. Don’t suffer in silence`}</a>{`. A community exists to support you, just have to seek out and reach out.`}</p>
    <p><strong parentName="p">{`Focus on the commonalities`}</strong>{`. While the finer threads connecting military roles to civilian jobs may not always be strong, the encompassing strategy is the same. There is a job to be done, a team put together the get the job done, and specific parameters and expectations in which the job is to be done. Channel the energy and strong skill set you possess towards positive momentum in your new career.`}</p>
    <p><strong parentName="p">{`Set realistic timeframes for adjustments`}</strong>{`. Two days on the job won’t leave you fully adjusted. The first 4-6 weeks in a new job are often training and information overload. Expect this and plan for it. If anything, veterans are apt to adapt and envelope loads of information under stressful situations, just remember those skills still exist it’s only the stream and source of information that has changed.`}</p>
    <p>{`A smooth transition out of the military can be difficult, so here are some helpful tips to set yourself up for success in your new endeavors!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      